export const BOOKING_ACTIONS = {
  activateBooking: 'activate_booking',
  resendBooking: 'resend_booking',
  sendBypassCode: 'send_bypass_code',
  terminateAsUser: 'terminate_as_user',
  terminateAsAdmin: 'terminate_as_admin',
  cancelWithFees: 'cancel_with_fees',
  cancelWithoutFees: 'cancel_without_fees',
  cancelUnavailability: 'cancel_unavailability',
  finishUnavailability: 'finish_unavailability',
  forceFinish: 'force_finish',
};

const dataTestPrefix = 'booking-action';

export function startingActions() {
  return [
    {
      label: 'Activate booking',
      dataTestId: `${dataTestPrefix}-${BOOKING_ACTIONS.activateBooking}`,
      action: () => this.patchActivateBooking(this.bookingUuid),
    },
    {
      label: 'Resend booking to vehicle',
      dataTestId: `${dataTestPrefix}-${BOOKING_ACTIONS.resendBooking}`,
      action: () => this.patchResendBooking(this.bookingUuid),
    },
  ];
}

export function finishingActions(hasBookingStarted) {
  return [
    {
      label: 'Send bypass code',
      dataTestId: `${dataTestPrefix}-${BOOKING_ACTIONS.sendBypassCode}`,
      action: () => this.postBookingBypassCode(this.bookingUuid),
    },
    {
      label: 'Terminate as user',
      disabled: !hasBookingStarted,
      dataTestId: `${dataTestPrefix}-${BOOKING_ACTIONS.terminateAsUser}`,
      action: () => this.patchTerminateBooking(this.bookingUuid),
    },
    {
      label: 'Terminate as admin',
      disabled: !hasBookingStarted,
      dataTestId: `${dataTestPrefix}-${BOOKING_ACTIONS.terminateAsAdmin}`,
      action: () => this.patchTerminateByAdminBooking(this.bookingUuid),
    },
  ];
}

export function cancellingActions(hasBookingStarted) {
  return [
    {
      label: 'Cancel with cancellation fees',
      disabled: hasBookingStarted,
      dataTestId: `${dataTestPrefix}-${BOOKING_ACTIONS.cancelWithFees}`,
      action: () => this.cancelBooking(true),
    },
    {
      label: 'Cancel without cancellation fees',
      disabled: hasBookingStarted,
      dataTestId: `${dataTestPrefix}-${BOOKING_ACTIONS.cancelWithoutFees}`,
      action: () => this.cancelBooking(false),
    },
  ];
}

export const BOOKING_ACTIONS_ERRORS = ['carsharing.booking_cancelled_with_errors'];
