import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
const {
  getOperatorConfigs,
  getLocations,
  getVehiclesByLocation,
} = backOffice.planning;

const {
  postBookings,
  postBookingsPrice,
  getBookingUserProfiles,
  getBookingTypes,
  getBookingRules,
  getDedicatedHours,
} = backOffice.bookings;

export const scopes = {
  booking: 'booking',
  bookingPrice: 'price',
  bookingRules: 'rules',
  bookingTypes: 'types',
  bookingUserProfiles: 'profiles',
  operatorConfigs: 'configs',
  locations: 'locations',
  vehicles: 'vehicles',
  dedicatedHours: 'dedicatedHours',
};

const planningCreateModel = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    clearProfiles(state) {
      state.profiles.data.profiles = {};
      state.profiles.data.circles = null;
    },
    clearBookingError(state) {
      state[scopes.booking].error = null;
      state[scopes.locations].error = null;
      state[scopes.vehicles].error = null;
    },
    resetVehicleAllocationsData(state) {
      state[scopes.locations].data = [];
      state[scopes.vehicles].data = [];
    },
  },
  actions: {
    async getOperatorConfigs({ commit }, params) {
      await runAsyncFlow(commit, {
        request: getOperatorConfigs,
        params: [params],
        scope: scopes.operatorConfigs,
      });
    },
    async getBookingUserProfiles({ commit }, { userUuid, bookingType }) {
      await runAsyncFlow(commit, {
        request: getBookingUserProfiles,
        params: [userUuid, { bookingType }],
        scope: scopes.bookingUserProfiles,
      });
    },
    async getDedicatedHours({ commit }, params) {
      commit('clearData', { scopes: [scopes.dedicatedHours], defaultValue: null });
      await runAsyncFlow(commit, {
        request: getDedicatedHours,
        params: [params],
        scope: scopes.dedicatedHours,
      });
    },
    async getBookingTypes({ commit }, params) {
      await runAsyncFlow(commit, {
        request: getBookingTypes,
        params: [params],
        scope: scopes.bookingTypes,
      });
    },
    async getBookingRules({ commit }, params) {
      await runAsyncFlow(commit, {
        request: getBookingRules,
        params: [params],
        scope: scopes.bookingRules,
      });
    },
    async postBookingsPrice({ commit }, params) {
      await runAsyncFlow(commit, {
        request: postBookingsPrice,
        params: [params],
        scope: scopes.bookingPrice,
      });
    },
    async postBookings({ commit }, params) {
      await runAsyncFlow(commit, {
        request: postBookings,
        params: [params, 'v2'],
        scope: scopes.booking,
      });
    },
    async getLocations({ commit }, params) {
      await runAsyncFlow(commit, {
        request: getLocations,
        params: [params],
        scope: scopes.locations,
      });
    },
    async getVehiclesByLocation({ commit, state }, { locationUuid, params }) {
      await runAsyncFlow(commit, {
        request: getVehiclesByLocation,
        params: [locationUuid, params],
        scope: scopes.vehicles,
      });

      if (state[scopes.vehicles].STATUS.ERROR) {
        commit('setData', { scope: scopes.vehicles, value: [] });
      }
    },
  },
})));

export default planningCreateModel;
